<template>
  <article id="Imprint">
      <div class="container">
          <div class="row-fluid">
              <p class="viewTitle">Imprint, Copyright, and Disclaimer</p>
              <p class="viewContentBold">
                  Responsible content provider according to §5 of the TMG
              </p>
              <p class="viewContent">
                  Senckenberg Society for Nature Research <br />Leibniz Institution for
                  Biodiversity and Earth System Research (SGN)
              </p>

              <p class="viewContent">
                  Senckenberganlage 25 <br />60325 Frankfurt <br />Phone: +49 69 7542 0
                  <br />Fax: +49 69 7542 1437 <br />E-Mail:<a href="mailto:info@senckenberg.de">info@senckenberg.de</a>
              </p>

              The Senckenberg Society for Nature Research – Leibniz Institution for
              Biodiversity and Earth System Research (SGN) is an unincorporated association
              according to § 22 of the BGB (German Civil Code) that was awarded the rights
              of a legal person by sovereign decree on 17 August 1867. According to § 28 of
              the currently applicable statute, the association as defined by § 26 of the
              BGB is represented judicially and extrajudicially by two members of the Board
              of Directors, which must include the Director General or the Deputy Director
              General.

              <p class="viewContentBold">Board of Directors</p>

              <p class="viewContent">
                  Prof. Dr. Klement Tockner (Director General) <br />Prof. Dr. Andreas Mulch
                  (Deputy Director General) <br />Dr. Martin Mittelbach (Administrative
                  Director) <br />Prof. Dr. Katrin Böhning-Gaese <br />Prof. Dr. Karsten
                  Wesche
              </p>

              <p class="viewContentBold">Supervisory Authority</p>

              <p class="viewContent">
                  Magistrate of the City of Frankfurt / Office of Public Order <br />USt-Id:
                  DE114235295
              </p>

              <p class="viewContentBold">Data Protection</p>
              <p class="viewContent">
                  The protection of personal data is very important to the SGN. Therefore, the
                  SGN collects and processes your data on this website exclusively in
                  accordance with the European provisions of the Basic Data Protection
                  Regulations (DSGVO) in accordance with the new Federal Data Protection
                  (BDSG-neu) and the Telemedia Act (TMG) for the SGN’s association-related
                  purposes. The SGN therefore only collects, uses, and passes on personal data
                  if this is permitted by law, or if you give consent for the data collection.
              </p>

              <p class="viewContentBold">
                  Legal Basis for the Processing of Data according to the DSGVO
              </p>
              <p class="viewContent">
                  The obtaining of consent is regulated by Article 6, Paragraph 1 a and
                  Article 7 of the DSGVO; the legal basis for the processing in order to
                  conduct contractual measures and for the reply to inquiries is 6, Paragraph
                  1 b of the DSGVO. The processing for the fulfilment of legal obligations
                  occurs in accordance with Article 6, Paragraph 1 c of the DSGVO. The legal
                  basis for the processing to safeguard Senckenberg‘s legitimate interests is
                  Article 6, Paragraph 1 f of the DSGVO. In the event that the essential
                  interests of the affected person or another natural person require the
                  processing of personal data, Article 6, Paragraph 1 d of the DSGVO applies.
              </p>

              <p class="viewContent">
                  At Senckenberg, this primarily involves the collection, storage, and
                  processing of your contact and user data
              </p>

              <ul class="viewContent">
                  <li>
                      for the mailing of donation receipts for your charitable donations in
                      accordance with Article 6, Paragraph 1 b of the DSGVO,
                  </li>
                  <li>
                      for the cooperation with associated companies for the donation management
                      and direct debit at the bank,
                  </li>
                  <li>
                      the mailing of the SGN newsletter, subject to your consent according to
                      Article 6, Paragraph 1 a,
                  </li>
                  <li>
                      for the provision of the online content, maintaining the website’s
                      functionality, and for marketing according to Article 6, Paragraph 1 f of
                      the DSGVO.
                  </li>
                  <li>
                      Beyond that, we principally do not pass on your data to any third parties,
                      in accordance with Article 6, Paragraph 1 b of the DSGVO.
                  </li>
              </ul>

              <p class="viewContentBold">Transmission into Third Countries</p>
              <p class="viewContent">
                  Data will only be processed in a third country (i.e., outside the European
                  Union (EU) or the European Economic Area (EEA)) if this is required for the
                  fulfilment of contractual obligation, based on your consent, due to a legal
                  obligation, or on the basis of our legitimate interests. The data will only
                  be processed in a third country in the presence of the special conditions
                  outlined in Article 44 et seq. of the DSGVO (officially recognized
                  determination of a data protection level in keeping with EU regulations,
                  e.g., by the “Privacy Shield” in the USA or officially recognized, special
                  contractual obligations, so-called “standard contract clauses”).
              </p>

              <p class="viewContentBold">Consent and Right to Objection</p>
              <p class="viewContent">
                  You can informally object to the use of your data at any time. Under certain
                  conditions according to Article 15 et seq. of the DSGVO, you further have
                  the right to information, correction, blocking, and deletion of the data
                  stored by us. <br />For this purpose, please contact us via e-mail at
                  <a href="mailto:datenschutz@senckenberg.de">datenschutz@senckenberg.de</a>
                  or via regular mail to
              </p>

              <p class="viewContent">
                  Senckenberg – Leibniz Institution for Biodiversity and Earth System Research
                  <br />Datenschutzbeauftragte (Data Protection Officer)
                  <br />Senckenberganlage 25 <br />60325 Frankfurt am Main
              </p>

              <p class="viewContent">
                  Moreover, according to Article 77 of the DSGVO in conjunction with § 40 of
                  the BDSG-neu, under certain conditions you have the right to file a
                  complaint with a supervisory authority.
              </p>

              <p class="viewContentBold">Personal Data</p>
              <p class="viewContent">
                  Personal data involve all information regarding your personal or factual
                  circumstances that can be used to identify a person. This includes
                  information such as your name, mailing address, telephone number, and e-mail
                  address. Information that cannot be directly associated with your true
                  identity (e.g., length of visit to a website or number of website users that
                  cannot be associated with your IP address) do not fall in this category.
              </p>
              <p class="viewContent">
                  Moreover, transmitted personal data will not be passed on to third parties,
                  unless this is urgently required by law. They are deleted upon conclusion of
                  the business process and expiry of the legal retention requirements, in
                  accordance with the statutory provisions.
              </p>

              <p class="viewContentBold">Newsletter</p>
              <p class="viewContent">
                  To ensure that you do not miss anything, we will gladly keep you informed
                  about our activities (such as special exhibitions, events, sweepstakes,
                  etc.) with our electronic Senckenberg Newsletter. In addition, you receive
                  our program brochure, which will keep you up-to-date on exhibitions,
                  lectures, guided tours, and special offers for families and children.
              </p>
              <p class="viewContent">
                  For this, we need your consent that the SGN will only collect, process, and
                  use your name and e-mail address for the mailing of the newsletter, the
                  communication of internal offers, and exclusively for internal statistical
                  purposes. You can revoke your consent to the processing and use of your
                  personal data and the mailing of the newsletter at any time via e-mail to
                  <a href="mailto:marketing@senckenberg.de">marketing@senckenberg.de</a>.
              </p>
              <p class="viewContent">Your data will then be deleted immediately.</p>

              <p class="viewContentBold">Server Logfiles/Access Data</p>

              <p class="viewContent">
                  Access to the websites of this internet presence is logged in accordance
                  with Article 6, Paragraph 1 f of the DSGVO in conjunction with the BDSG-neu.
                  This includes: name of the website, file name, date and time of access,
                  transmitted data volume, browser type, the user’s operating system, referrer
                  URL, and IP address. The data are analyzed for statistical purposes and to
                  improve the content offer. They are deleted at regular intervals. The
                  provisions of the TMG apply.
              </p>

              <p class="viewContentBold">
                  Incorporation of third-party Services and Content
              </p>
              <p class="viewContent">
                  It is possible that third-party online content such as YouTube videos, maps
                  by Google Maps, RSS feeds, or graphics from other websites will be
                  incorporated in our online content. This always requires that the content
                  provider (hereafter referred to as third-party provider) records the user’s
                  IP address, since the content cannot be transmitted to the respective user’s
                  browser without the IP address. The IP address is therefore necessary for
                  the display of the content. We make an effort to only offer content by
                  providers that will use the IP address exclusively for the provision of this
                  content. However, we have no influence over the possibility that a
                  third-party provider may store the IP address for statistical purposes, etc.
                  To the extent that this is known to us, we will inform the user in this
                  regard.
              </p>

              <p class="viewContentBold">Cookies</p>

              <p class="viewContent">
                  This internet presence uses cookies. These are small profile files that are
                  stored on your hard drive. They enable us to detect whether you visit our
                  internet presence again. This information cannot be associated with a
                  specific person. Cookies are deleted at yearly intervals. You can also
                  deactivate the cookies. For details, please refer to your web browser‘s user
                  manual. If you deactivate cookies, the visit of our internet presence may be
                  restricted.
              </p>

              <p class="viewContentBold">Facebook and Twitter Button</p>

              <p class="viewContent">
                  You can access the following third-party services via these pages:
              </p>
              <p class="viewContent">
                  Social Plugins (e.g., “Like” button) of the social media network
                  facebook.com. It is operated by Facebook Inc., 1601 S. California Ave, Paolo
                  Alto, CA 943024, USA. <br />
                  The “Twitter” button (Twitter in conjunction with a “blue bird”). It is
                  offered by Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107,
                  USA.
              </p>

              <p class="viewContent">
                  When you call up the Facebook social plugin or Twitter, you establish a
                  direct connection to the servers of the respective provider, which may be
                  located outside of Europe. The SGN has no influence over the volume of data
                  collected by these companies and cannot be held liable for compliance with
                  the data protection regulations. This is always the sole responsibility of
                  the respective content provider or operator of these pages.
              </p>
              <p class="viewContent">
                  You can learn more about your rights and optional settings for the
                  protection of your privacy at the following links:
              </p>
              <p class="viewContent">
                  <a href="https://www.facebook.com/about/privacy/"
                      target="blank">https://www.facebook.com/about/privacy/</a><br />

                  <a href="http://twitter.com/privacy" target="blank">http://twitter.com/privacy</a>
              </p>

              <br />
              <p class="viewContentBold">Firewall</p>
              <p class="viewContent">
                  A firewall monitors and controls the complex data traffic and prevents
                  unauthorized access.
              </p>

              <p class="viewContentBold">Storage Limits</p>
              <p class="viewContent">
                  All personal data will be deleted immediately after conclusion of their
                  processing purpose.
              </p>

              <p class="viewContentBold">
                  Data Protection Notice According to § 13 of the TMG
              </p>
              <p class="viewContent">
                  According to the provisions of the TMG, we point out that all personal data
                  transmitted to us via this website will not be processed and stored beyond
                  the time period required for the service provision, and not to an extent
                  that exceeds the data volume essentially necessary for the service
                  provision.
              </p>

              <p class="viewContentBold">Copyright</p>
              <p class="viewContent">
                  All content, images, and graphics are protected by copyright and may only be
                  used with the written permission of the respective copyright holder.
              </p>

              <p class="viewContentBold">Liability for Links</p>
              <p class="viewContent">
                  Our content includes links to third-party external web pages (e.g.,
                  YouTube), over whose content we have no influence. To display the content,
                  your IP address is transmitted to the third party. The SGN cannot accept any
                  liability for this foreign content and compliance with the data protection
                  regulations. The content of the linked pages is always the sole
                  responsibility of the websites’ respective content provider or operator.
              </p>

              <p class="viewContentBold">Imprint</p>
              <p class="viewContent">
                  The use of contact data published in the context of the obligation to
                  provide an imprint by third parties for the mailing of unsolicited
                  advertisements and information materials is hereby expressly prohibited. The
                  operator of the websites expressly reserve the right to take legal action in
                  case of the unsolicited mailing of advertising information, e.g., by means
                  of spam e-mails.
              </p>
          </div>
      </div>
  </article>
</template>

<script>

</script>

  <style>
      .viewTitle {
          font-weight: 700;
          font-size: 1.2rem;
          text-align: left;
      }

      .viewContent {
          font-weight: 400;
          text-align: left;
      }

      .viewContentBold {
          font-weight: 700;
          text-align: left;
      }
  </style>
