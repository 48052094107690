<template>
  <Dialog v-model:visible="dialog.visibility">
    <template #header>
      <h3>{{ dialog.header }}</h3>
    </template>

    {{ dialog.content }}

    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="formDeleteOWnDocumentNo()"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        autofocus
        @click="formDeleteOWnDocumentYes()"
      />
    </template>
  </Dialog>

  {{ cordraDataset.length === 0 ? "There're no datasets." : subtitle }}
  <!-- <Fieldset
    v-if="this.$keycloakGlobalstate.authenticated"
    :legend="LEgend"
    toggleable="true"
    collapsed="false"
    class="formItems"
    @toggle="showNewOrOldDatasets = !showNewOrOldDatasets"
  > -->

  <!-- All these classes "dataDocuments", "dataDocumentsPanel" and "dataDocumentsPanelContent" are definded in App.vue -->

  <div class="dataDocuments" v-for="item in cordraDataset" :key="item">
    <Panel toggleable collapsed class="dataDocumentsPanel">
      <template #header
        ><span>{{ item.content.datasetName }}</span></template
      >
      <span
        >Creators:
        <span class="dataDocumentsPanelContent">{{
          item.content.creators
        }}</span></span
      ><br /><br />

      <span
        >Abstract:
        <span class="dataDocumentsPanelContent">{{
          item.content.abstract
        }}</span></span
      ><br /><br />

      <span
        >Contact Organizations:
        <span class="dataDocumentsPanelContent">{{
          item.content.contactOrganizations
        }}</span></span
      ><br /><br />

      <span
        >Created on:
        <span class="dataDocumentsPanelContent">{{
          Date(item.metadata.createdOn)
        }}</span></span
      ><br />
      <span
        >Modified on:
        <span class="dataDocumentsPanelContent">{{
          Date(item.metadata.modifiedOn)
        }}</span></span
      ><br /><br />

      <span v-if="item.payloads"
        >Payloads:
        <span class="dataDocumentsPanelContent">{{
          item.payloads[0].name
        }}</span></span
      >

      <template #icons v-if="this.$keycloakGlobalstate.authenticated">
        <button
          class="p-panel-header-icon p-link p-mr-2"
          @click="formDeleteOWnDocument(item.content.datasetName, item.id)"
        >
          <span class="pi pi-times-circle"></span>
        </button>
      </template>
    </Panel>
  </div>
  <!-- </Fieldset> -->
</template>

<script>
import Panel from "primevue/panel";
import Button from "primevue/button";
import Dialog from "primevue/dialog";

export default {
  name: "DataOwnAndPublicDocs",
  components: { Panel, Button, Dialog },
  inject: ["appConfig", "cordraCreateTemporaryClient"],
  emits: ["set-message"],

  data: function () {
    return {
      messages: [],
      dialog: {
        header: "",
        content: "",
        visibility: false,
      },

      subtitle: "",
      cordraDataset: [],

      socotraDeleteDocumentID: "",

      formNewDataset: {
        datasetName: "",
        creators: "",
        abstract: "",
        contactOrganizations: "",
      },
    };
  },

  methods: {
    cordraShowPublicDocuments: function () {
      this.cordraDataset = [];
      let cordraClient = this.cordraCreateTemporaryClient("public");

      cordraClient
        .search("type:" + this.appConfig.documentType)
        .then((response) => {
          response.results.forEach((result) => {
            this.cordraDataset.push(result);
            this.subtitle =
              this.cordraDataset.length + " public datasets found.";
          });
        });
    },

    cordraShowAuthorDocuments: function () {
      this.cordraDataset = [];

      // public for search
      let cordraClient = this.cordraCreateTemporaryClient("public");

      cordraClient
        .search(
          "type:" +
            this.appConfig.documentType +
            " AND createdBy:" +
            this.$keycloak_authorID()
        )
        .then((response) => {
          response.results.forEach((result) => {
            this.cordraDataset.push(result);
          });
        });
    },

    // Forms
    formDeleteOWnDocument: function (documentName, documentID) {
      this.socotraDeleteDocumentID = documentID;

      this.dialog.header = "Delete dataset " + documentName + "?";
      this.dialog.content =
        "Are you sure you want to delete the dataset " +
        documentName +
        "with the id " +
        this.socotraDeleteDocumentID;
      this.dialog.visibility = true;
    },

    formDeleteOWnDocumentYes: function () {
      this.dialog.header = "";
      this.dialog.content = "";
      this.dialog.visibility = false;

      window.scrollTo(0, 0);

      this.$emit("set-message", {
        severity: "success",
        content:
          "Try to delete selected dataset. Please wait for conformition 1.",
      });

      let cordraClient = this.cordraCreateTemporaryClient("private");

      cordraClient
        .delete(this.socotraDeleteDocumentID)
        .then(() => {
          setTimeout(() => {
            this.$emit("set-message", {
              severity: "success",
              content: "Success, your dataset was deleted.",
            });

            console.log("DataOwnPublicDocs cordraShowAuthorDocuments ...");
            this.cordraShowAuthorDocuments();
          }, 500);
        })
        .catch((error) => {
          this.$emit("set-message", { severity: "warn", content: error });
        });

      this.socotraDeleteDocumentID = "";
    },

    formDeleteOWnDocumentNo: function () {
      this.socotraDeleteDocumentID = "";
      this.dialog.header = "";
      this.dialog.content = "";
      this.dialog.visibility = false;
    },
  },

  mounted() {
    this.$keycloakGlobalstate.authenticated
      ? this.cordraShowAuthorDocuments()
      : this.cordraShowPublicDocuments();
  },
};
</script>

<style lang="scss">
.formInputs {
  width: 100%;
}
.formItems {
  margin-top: 1.2rem;
}
</style>
