<template>
  <div class="text-medium font-medium text-left content-text">
    <p class="text-xl font-bold">Living Atlas</p>
    <p class="font-bold">
      <iframe
        style="border: 0px"
        width="100%"
        height="800px"
        src="/FishAtlas/index.html"
      ></iframe>
    </p>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: function () {
    return {};
  },
};
</script>

<style lang="scss"></style>
