<template>
  <div class="text-medium font-medium text-left content-text">
    <div class="text-xl font-bold">Collaborators</div>

    <div class="font-bold text-center collaborators-title">
      Funding, Implementing and Executing Agencies
    </div>
    <table>
      <tr>
        <td>
          <template v-for="idx in agencies" :key="idx">
            <div class="collaborators">
              <Card style="width: 10rem">
                <template #header>
                  <img :alt="idx.name" :src="idx.src" />
                </template>
                <template #subtitle> {{ idx.name }} </template>
              </Card>
            </div>
          </template>
        </td>
      </tr>
    </table>

    <div class="font-bold text-center collaborators-title">
      Collaborating Institions
    </div>
    <table>
      <tr>
        <td>
          <template v-for="idx in institutions" :key="idx">
            <div class="collaborators">
              <Card style="width: 10rem">
                <template #header>
                  <img :alt="idx.name" :src="idx.src" />
                </template>
                <template #subtitle> {{ idx.name }} </template>
              </Card>
            </div>
          </template>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Card from "primevue/card";

export default {
  name: "Collaborators",
  components: { Card },
  data: function () {
    return {
      agencies: [
        {
          name: "Royal Society for The Conservation of Nature",
          link: "https://www.rscn.org.jo/",
          src: require("./../assets/content/collaborators/agency_1.jpeg"),
        },
        {
          name: "Global Environment Facility",
          link: "https://www.thegef.org/",
          src: require("./../assets/content/collaborators/agency_2.jpeg"),
        },
        {
          name: "United Nations Environment Programme",
          link: "https://www.unep.org/",
          src: require("./../assets/content/collaborators/agency_3.jpeg"),
        },
        {
          name: "Environmental Protection Authority (Yemen)",
          link: "https://epa-ye.org/",
          src: require("./../assets/content/collaborators/agency_4.jpeg"),
        },
        {
          name: "Senckenberg – Leibniz Institution for Biodiversity and Earth System Research",
          link: "https://senckenberg.de/",
          src: require("./../assets/content/collaborators/agency_5.jpeg"),
        },
      ],
      institutions: [
        {
          name: "BirdLife International",
          link: "https://www.birdlife.org/",
          src: require("./../assets/content/collaborators/institution_1.jpeg"),
        },
        {
          name: "Friends of Soqotra",
          link: "https://www.friendsofsoqotra.org/",
          src: require("./../assets/content/collaborators/institution_2.jpeg"),
        },
        {
          name: "Ghent University",
          link: "https://www.ugent.be/",
          src: require("./../assets/content/collaborators/institution_3.jpeg"),
        },
        {
          name: "Mendel University in Brno",
          link: "https://mendelu.cz/",
          src: require("./../assets/content/collaborators/institution_4.jpeg"),
        },
        {
          name: "Ministry Water and Environment Yemen",
          link: "http://www.mwe.gov.ye/",
          src: require("./../assets/content/collaborators/institution_5.jpeg"),
        },
        {
          name: "Naturhistorisches Museum Bern",
          link: "https://www.nmbe.ch/",
          src: require("./../assets/content/collaborators/institution_6.jpeg"),
        },
        {
          name: "Royal Botanic Garden Edinburgh",
          link: "https://www.rbge.org.uk/",
          src: require("./../assets/content/collaborators/institution_7.jpeg"),
        },
        {
          name: "Centre for Middle Eastern Plants",
          link: "http://www.cmep.org.uk/",
          src: require("./../assets/content/collaborators/institution_8.jpeg"),
        },
        {
          name: "Sapienza University of Rome",
          link: "https://www.uniroma1.it/",
          src: require("./../assets/content/collaborators/institution_9.jpeg"),
        },
        {
          name: "Socotra Karst Project",
          link: "http://thesocotrakarstproject.blogspot.com/",
          src: require("./../assets/content/collaborators/institution_10.jpeg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.collaborators-title {
  float: middle;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.collaborators {
  float: left;
  padding-right: 2rem;
  display: block;
}
</style>
