<template>
    <article id="Mapview">
        <div id="mpcontainer">
            <div class="row-fluid">
                <div class="text-left">

                    <!-- map-content here ... -->
                    <div class="fullscreen">

                        <div id="map" class="map">
                            <div id="info">
                                <strong>Help</strong>
                                <p>Click on the info icon in order to get more information about the layers.</p>
                                <p>Click on the layers in order to get specific attribution information (if available).</p>
                            </div>
                        </div>

                        <!-- Mapaufbau-js funktioniert nur unterhalb der id-map !?
                        <script type="text/javascript" src="${pageContext.request.contextPath}/js/mapcontrolsmall.js"></script>-->

                        <div class="legendbox">
                            <span class="legend_title">Show / Hide Legends:</span>
                            <div class="legendlinks">
                                <a id="show_legend1" href="#" title="Show / Hide Legend for Elevation" onclick="show_legend('legend1')">Elevation </a>

                                <a id="show_legend2" href="#" title="Show / Hide Legend for Marine Zoning Plan" onclick="show_legend('legend2')">Marine Zoning Plan </a>
                                <a id="show_legend3" href="#" title="Show / Hide Legend for Terrestrial Zoning Plan" onclick="show_legend('legend3')">Terrestrial Zoning Plan </a>
                                <a id="show_legend4" href="#" title="Show / Hide Legend for Aspect" onclick="show_legend('legend4')">Aspect </a>
                                <a id="show_legend5" href="#" title="Show / Hide Legend for Slope" onclick="show_legend('legend5')">Slope </a>
                                <a id="show_legend6" href="#" title="Show / Hide Legend for DEM" onclick="show_legend('legend6')">DEM </a>
                                <a id="show_legend7" href="#" title="Show / Hide Legend for Landcover" onclick="show_legend('legend7')">Landcover</a>
                                <a id="show_legend8" href="#" title="Show / Hide Legend for Geology" onclick="show_legend('legend8')">Geology </a>

                                <a id="show_legend9" href="#" title="Show / Hide Legend for Principal Vegetation Types of Miller &amp; Morris (2004)" onclick="show_legend('legend9')">Principal Vegetation Types</a>
                                <a id="show_legend10" href="#" title="Show / Hide Legend for Landunits" onclick="show_legend('legend10')">Landunits</a>
                                <a id="show_legend11" href="#" title="Show / Hide Legend for Vegetation Map" onclick="show_legend('legend11')">Vegetation Map</a>
                            </div>
                        </div>

                        <div id="legend1">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=10&HEIGHT=10&LAYER=soco:soc_elev_poly_WGS84&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:6;bgColor:0xFFFFEE;dpi:180;">
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Elevation-->
                        <div id="legend2">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=8&HEIGHT=8&LAYER=soco:soc_zoning_marine_poly_WGS84&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Marine Zoning Plan-->
                        <div id="legend3">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=8&HEIGHT=8&LAYER=soco:terr_zon&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Terrestria Zoning Plan-->
                        <div id="legend4">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=20&WIDTH=15&LAYER=soco:ASP_new&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Aspect -->
                        <div id="legend5">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=20&WIDTH=15&LAYER=soco:SLP_new&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Slope -->
                        <div id="legend6">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=20&WIDTH=15&LAYER=soco:DEM_new2&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer DEM -->
                        <div id="legend7">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=16&WIDTH=16&LAYER=soco:Landcover_Socotra&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Landcover -->
                        <div id="legend8">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=24&WIDTH=24&LAYER=soco:geol_simpl&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Geology -->
                        <div id="legend9">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=24&WIDTH=24&LAYER=soco:miller&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Miller Veg. Map -->
                        <div id="legend10">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=24&WIDTH=24&LAYER=soco:land_unit_WGS84&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Landunits -->
                        <div id="legend11">
                            <table border="0">
                                <tr>
                                    <td valign="bottom"><img
                                        src="/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=12&WIDTH=12&LAYER=soco:VegMap_wgs84Ras&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:4;bgColor:0xFFFFEE;dpi:250;"></td>
                                </tr>
                            </table>
                        </div>
                        <!-- Legende fuer Vegetation Map  -->
                        <div id="tooltip" style="background-color: rgba(127, 127, 127, 0.7); font-size: 11pt; color: white; padding: 8px;"></div>
                    </div>

                </div>
            </div>
        </div>
    </article>
</template>

<script>
import "./../assets/css/ol.css";
import "./../assets/css/general.css";
import "./../assets/css/map/olmpdefault.css";
import "./../assets/css/map/layerswitchercontrol.css";
import "./../assets/css/map/bootstrap.min.css";
import "./../assets/css/map/legend.css";
import "./../assets/css/style.css";

export default {

  beforeCreate(){
    ///console.log('beforeCreate');
    //window.location.reload();
     /**
      * In der Entwicklungsumgebung läuft alles okay, aber in der Produktionsumgebung führt ein Blättern
      * dazu, dass die Seite sich neu aufbaut und unter die alte drangehängt wird. Wie man Ajax-Refresh
      */
    if (localStorage.getItem('reloaded')) {
        localStorage.removeItem('reloaded');
    } else {
        localStorage.setItem('reloaded', '1');
        location.reload();
    }


  },
  mounted() {
    console.log('mounted');
    var scripts = [
      "/socotra-demo/js/mapAttributions.js",
      "/socotra-demo/js/map.js",
      "/socotra-demo/js/requestAnimationFrame.js",
      "/socotra-demo/js/soco.js",
      "/socotra-demo/js/layerswitchercontrol.js",
      "/socotra-demo/js/mapcontrol.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  beforeUpdate(){
    console.log('before Update ...');
  },
  updated(){
    console.log('Updated ...');
  },

};

</script>


<style lang="scss"></style>
