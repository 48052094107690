<template>
    <article id="Toolbox">
        <div id="tbcontainer">
                    <div class="text-center living-content">
                        <div id="tab1" class="tab">
                            <div>
                                <div id="map" class="map" style="height: 400px">
                                    <div id="info">
                                      <strong>Help</strong>
                                      <p>Click on the info icon in order to get more information about the layers.</p>
                                      <p>Click on the layers in order to get specific attribution information (if available).</p>
                                    </div>
                                </div>

                                <!-- <div style="line-height:1.5em;font-size: 12px;font-family:Arial,Helvetica,sans-serif;color: #5a5a5a; width:95%"> -->
                                  <div>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="tb">
                                      <tr>
                                        <td colspan="3"><label>Choose a terrestrial zone by clicking in the map or by selecting a check box below. You will get a list of all selected species that intersect with this polygon.</label></td>
                                      </tr>
                                      <tr>
                                        <td class="leftcol">
                                          <form>
                                            <table class="optic_choice" width="100%" border="0" cellspacing="0" cellpadding="0">
                                              <tr>
                                                <td class="area" valign="top">
                                                    <h3 style="padding-left:5px;">Terrestrial Zone</h3>
                                                    <div style="color:black;margin-bottom:5px;padding-left:5px;font-weight:bold;">Conservation and Development<br>Zones (of SCZP)</div>
                                                    <div style="background-color:rgba(192,192,192,0.2);border-radius:3px;width:85%;padding:5px">
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="2" />Hadiboh General Use Zone</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="1" />Qalansiya General Use Zone</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="3" />Port General Use Zone</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="16" />Resouce Use Reserve</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="4" />National Parks</label></div>
                                                    </div>

                                                    <div style="color:black;margin:10px 0 5px 0;padding-left:5px;font-weight:bold">Nature Sanctuaries (of SCZP)</div>
                                                    <div style="background-color:rgba(192,192,192,0.2);border-radius:3px;width:85%;padding:5px">
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="0" checked="true" />None</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="5" />Homhil</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="6" />Skent</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="7" />Sarahin</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="8" />Neet</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="9" />Wadi Shuaab</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="10" />Shuab Mangrove</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="11" />Detwah</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="12" />Djabel Salah</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="13" />Samha limestone escarparments</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="14" />Darsah island</label></div>
                                                      <div><label><input onclick="refreshMap()" name="zone" type="radio" value="15" />Kal Farun</label></div>
                                                    </div>

                                                </td>
                                                <td class="area" valign="top">
                                                  <h3>Species Type</h3>
                                                <div style="background-color:rgba(192,192,192,0.2);border-radius:3px;width:70%;padding:5px">
                                                  <!-- Bats and Shrews are checked because plants are more than 12000 records ! -->
                                                  <div><label><input onclick="refreshMap()" name="species" type="radio" value="0" />Plants</label></div>
                                                  <div><label><input onclick="refreshMap()" name="species" type="radio" value="5" checked="true" />Bats and Shrews</label></div>
                                                  <div><label><input onclick="refreshMap()" name="species" type="radio" value="1" />Crabs</label></div>
                                                  <div><label><input onclick="refreshMap()" name="species" type="radio" value="4" />Dragonflies</label></div>
                                                  <div><label><input onclick="refreshMap()" name="species" type="radio" value="2" />Grasshopper</label></div>
                                                  <div><label><input onclick="refreshMap()" name="species" type="radio" value="3" />Molluscs</label></div>
                                                  <div><label><input onclick="refreshMap()" name="species" type="radio" value="6" />Reptiles</label></div>
                                                </div>
                                                </td>
                                              </tr>
                                            </table>

                                          </form>
                                        </td>
                                        <td class="rightcol">
                                          <h3>Data</h3>
                                          <div id="result" style="border:1px solid #aaa;height:350px;overflow:auto">
                                            <div id="loading" style="position:relative;top:150px;left:0px;display:none">Loading...</div>
                                            <table width="100%">
                                              <thead>
                                                <th align="left">ID</th>
                                                <th align="left">Species</th>
                                                <th align="left">Family</th>
                                                <th align="left">IUCN</th>
                                                <th align="left">Endemic</th>
                                                <th align="left">Source</th>
                                              </thead>
                                              <tbody id="tablerowitem">

                                                  <!--
                                                  <tr>
                                                      <td>{{json.properties.gid ? json.properties.gid : '-'}}</td>
                                                      <td>{{json.properties.species ? json.properties.species : '-'}}</td>
                                                      <td>{{json.properties.family ? json.properties.family : '-'}}</td>
                                                      <td>{{json.properties.iucncat ? json.properties.iucncat : '-'}}</td>
                                                      <td>{{json.properties.endemic ? json.properties.endemic : '-'}}</td>
                                                      <td>{{json.properties.source ? json.properties.source : '-'}}</td>
                                                  </tr>
                                                  -->
                                              </tbody>
                                            </table>
                                          </div>
                                          <div style="text-align:left"><span id="numfound">0</span><span> species found.</span></div>
                                        </td>
                                      </tr>
                                    </table>
                                </div>
                                <div id="tooltip" style="background-color:rgba(127,127,127,0.7);font-size:11pt;color:white;padding:8px;"></div>
                            </div>

                            <!-- Ende tab1 -->
                        </div>
    <!--
                        <div id="tab2" class="tab" style="display: none">
                            <h5>Work in progress ...</h5>
                            <p></p>
                        </div>
     -->
                   </div>
            <!-- </div> -->
        </div>
    </article>

</template>

<script>
import "./../assets/css/ol.css";
import "./../assets/css/toolbox/ol3-layerswitcher.css";
import "./../assets/css/toolbox/oltbdefault.css";
import "./../assets/css/general.css";
import "./../assets/css/style.css";
import "./../assets/css/footer.css";
import "./../assets/css/toolbox/tab-format.css";

export default {
  beforeCreate(){
    ///console.log('beforeCreate');
    //window.location.reload();
     /**
      * In der Entwicklungsumgebung läuft alles okay, aber in der Produktionsumgebung führt ein Blättern
      * dazu, dass die Seite sich neu aufbaut und unter die alte drangehängt wird. Wie man Ajax-Refresh
      */
    if (localStorage.getItem('reloaded')) {
        localStorage.removeItem('reloaded');
    } else {
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
  },
  mounted() {
    console.log('mounted toolbox');
    var scripts = [
      "/socotra-demo/js/tbfunctions.js",
      "/socotra-demo/js/requestAnimationFrame.js",
      "/socotra-demo/js/ol3-layerswitcher.js",
      "/socotra-demo/js/soco.js",
      "/socotra-demo/js/tbvector.js",
      "/socotra-demo/js/tbcontrol.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },

  beforeUpdate(){
    console.log('before Update ...');
  },
  updated(){
    console.log('Updated ...');
  },
};
</script>

<style lang="scss"></style>
