<template>
  <Message
    v-for="msg of messages"
    :severity="msg.severity"
    :key="msg.content"
    >{{ msg.content }}</Message
  >

  <div class="text-medium font-medium text-left content-text">
    <p class="text-xl font-bold">Data</p>

    <!-- Check Server -->
    <div v-if="keycloakReady == false">
      Connecting the authentication server for authors.
    </div>

    <!-- Log-In-Out -->
    <!-- There's no author, show public datasets -->
    <div
      v-if="keycloakReady && this.$keycloakGlobalstate.authenticated === false"
    >
      <a :href="this.$keycloakLoginLink()">Log-in for authors</a><br /><br />
      <DataOwnAndPublicDocs />
    </div>

    <!-- There's an author, show own objects or create a new one -->
    <div v-if="keycloakReady && this.$keycloakGlobalstate.authenticated">
      <a :href="this.$keycloakLogoutLink()">Log-out</a>
      <br /><br />
      <DataCreateForm
        @reload-cordracomponent="reloadCordracomponent"
        @set-message="setMessage"
      />
      <DataOwnAndPublicDocs
        v-if="!reloadCordracomponentFlag"
        @set-message="setMessage"
      />
    </div>
  </div>
</template>

<script>
import Message from "primevue/message";

// Cordra
import { CordraClient } from "@cnri/cordra-client";

// Subcomponents
import DataOwnAndPublicDocs from "./DataOwnAndPublicDocs.vue";
import DataCreateForm from "./DataCreateForm.vue";

export default {
  name: "Data",
  components: {
    Message,

    DataOwnAndPublicDocs,
    DataCreateForm,
  },
  inject: ["appConfig"],

  data: function () {
    return {
      messages: [],
      dialog: {
        header: "",
        content: "",
        visibility: false,
      },

      keycloakReady: false,
      reloadCordracomponentFlag: false,
    };
  },
  methods: {
    keycloakCheckServer: function () {
      // console.log("keycloakCheckServer");

      if (this.$keycloakGlobalstate.ready) {
        this.keycloakReady = true;
      } else {
        this.keycloakReady = false;
        setTimeout(() => this.keycloakCheckServer(), 200);
      }
    },

    setMessage: function (message) {
      this.messages.push(message);
    },

    reloadCordracomponent: function () {
      this.reloadCordracomponentFlag = true;
      setTimeout(() => {
        this.reloadCordracomponentFlag = false;
      }, 500);
    },
  },

  provide: function () {
    return {
      cordraCreateTemporaryClient: function (kind) {
        return kind === "private"
          ? new CordraClient(this.appConfig.cordraUrl, {
              token: this.$keycloak_token(),
            })
          : new CordraClient(this.appConfig.cordraUrl);
      },
    };
  },

  mounted() {
    this.keycloakCheckServer();
  },
};
</script>
