<template>
  <div class="text-medium font-medium text-left content-text">
    <p class="text-xl font-bold">Archive</p>
    <p class="font-bold">
      <iframe
        id="iFrame1"
        style="height:400px;border: none;"
        width="100%"
         src="/archive/index.html"
      ></iframe>
    </p>
  </div>
</template>

<script>

export default {
  name: "Home",
  components: {},
  data: function () {
  },
  methods: {
  },

  mounted: function () {
  },

  unmounted: function () {
  },
};

</script>

<style lang="scss">

</style>
