<template>
  <Fieldset
    legend="Add new dataset"
    :toggleable="true"
    v-model:collapsed="collapsed"
    class="fieldsetBorder"
  >
    <span class="p-float-label formItems">
      <InputText
        class="p-inputtext formInputs"
        id="formDatasetName"
        type="text"
        v-model="formNewDataset.datasetName"
      />
      <label for="formDatasetName">Dataset name</label>
    </span>

    <span class="p-float-label formItems">
      <InputText
        class="p-inputtext formInputs"
        id="formCreators"
        type="text"
        v-model="formNewDataset.creators"
      />
      <label for="formCreators">Creator(-s) and associated parties</label>
    </span>

    <span class="p-float-label formItems">
      <Textarea
        class="formInputs"
        :autoResize="true"
        rows="5"
        cols="30"
        id="formAbstract"
        v-model="formNewDataset.abstract"
      />
      <label for="formAbstract">Abstract</label>
    </span>

    <span class="p-float-label formItems">
      <Calendar
        class="formInputs"
        id="formCalendar"
        v-model="formNewDataset.Calendar"
        :monthNavigator="true"
        :yearNavigator="true"
        yearRange="1900:2030"
      />
      <label for="formCalendar">Publication Date</label>
    </span>

    <span class="p-float-label formItems">
      <Textarea
        class="formInputs"
        :autoResize="true"
        rows="5"
        cols="30"
        id="formContactOrganizations"
        v-model="formNewDataset.contactOrganizations"
      />
      <label for="formContactOrganizations">Contact organization(-s)</label>
    </span>

    <span class="formItems">
      <InputText
        class="p-inputtext p-component formInputs"
        type="file"
        id="formNewDataset.files"
      />
    </span>

    <div style="text-align: center; margin-top: 1rem">
      <Button
        label="Submit"
        @click="cordraCreateDocument()"
        class="p-button-raised p-button-rounded"
      />&nbsp;&nbsp;&nbsp;
      <Button
        label="Reset"
        @click="formResetNewDataset()"
        class="p-button-raised p-button-rounded"
      />
    </div>
  </Fieldset>
</template>

<script>
import Fieldset from "primevue/fieldset";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Textarea from "primevue/textarea";
import Button from "primevue/button";

export default {
  name: "DataCreateForm",
  components: {
    Fieldset,
    InputText,
    Calendar,
    Textarea,
    Button,
  },
  inject: ["appConfig", "cordraCreateTemporaryClient"],

  data: function () {
    return {
      collapsed: true,

      formNewDataset: {
        datasetName: "",
        creators: "",
        abstract: "",
        contactOrganizations: "",
      },
      emits: ["set-message"],
    };
  },

  methods: {
    formResetNewDataset: function () {
      this.formNewDataset = {
        datasetName: "",
        creators: "",
        abstract: "",
        contactOrganizations: "",
      };

      document.getElementById("formNewDataset.files").value = "";
    },

    cordraCreateDocument: function () {
      console.log("start create ...");
      window.scrollTo(0, 0);

      this.$emit("set-message", {
        severity: "success",
        content: "Try to report new dataset. Please wait for conformition.",
      });

      console.log("start create cordraCreateTemporaryClient ... ");
      let cordraClient = this.cordraCreateTemporaryClient("private");
      let payloads = () => {
        if (document.getElementById("formNewDataset.files").files.length > 0) {
          console.log("start document.getElementById ... ");
          return [
            {
              body: document.getElementById("formNewDataset.files").files[0],
              name: document.getElementById("formNewDataset.files").files[0]
                .name,
            },
          ];
        } else return [];
      };

      let cordraObject = {
        type: this.appConfig.documentType,
        content: {
          id: "",
          datasetName: this.formNewDataset.datasetName,
          creators: this.formNewDataset.creators,
          abstract: this.formNewDataset.abstract,
          contactOrganizations: this.formNewDataset.contactOrganizations,
        },
        payloads: payloads(),
      };

      cordraClient
        .create(cordraObject)
        .then(() => {
          console.log("DataCreateForm, Success, a new dataset was reported.");
          this.$emit("reload-cordracomponent");

          console.log("DataCreateForm, reload-cordracomponent finished ...");
          this.$emit("set-message", {
            severity: "success",
            content: "Success, a new dataset was reported.",
          });
          this.formResetNewDataset();
          this.collapsed = true;
        })
        .catch((error) => {
          console.log("####################################");
          console.log(error);
          console.log("####################################");
          this.$emit("set-message", { severity: "error", content: error });
        });
    },
  },
};
</script>
<style lang="scss">
.formInputs {
  width: 100%;
}
.formItems {
  margin-top: 1.2rem;
}
</style>
